<template>
    <div class="fc-form-corrections">
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.form.corrections.title') }}
        </pui-headline>
        <template v-for="correction in corrections">
            <div :key="correction.internalId">
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :label="$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionFrom')"
                        :is-valid="!correction.errorMessage"
                        v-pui-form-grid-column="3"
                    >
                        <pui-form-select
                            :label="$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionFrom')"
                            :options="mtpYearOptions"
                            :is-valid="!correction.errorMessage"
                            :value="correction.fromYear"
                            @change="onFromYearChange($event, correction)"
                        />
                        <template #error-message>
                            {{ correction.errorMessage }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        :label="$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionTo')"
                        :is-valid="!correction.errorMessage"
                        v-pui-form-grid-column="3"
                    >
                        <pui-form-select
                            :label="$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionTo')"
                            :options="mtcYearOptions"
                            :is-valid="!correction.errorMessage"
                            :value="correction.toYear"
                            @change="onToYearChange($event, correction)"
                        />
                    </pui-form-group>
                    <pui-form-group
                        :label="$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionPercentage')"
                        v-pui-form-grid-column="2"
                    >
                        <pui-form-input-field
                            :label="$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionPercentage')"
                            :value="correction.percentage.toString()"
                            @input="onPercentageChange($event, correction)"
                        />
                    </pui-form-group>
                    <pui-form-group
                        :label="$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionAmount')"
                        v-pui-form-grid-column="2"
                    >
                        <pui-form-input-field
                            :label="$t('setup.scenario.ceForm.tables.flagged.form.corrections.labels.correctionAmount')"
                            :is-disabled="true"
                            :value="formatCorrectionAmount(correction.amount)"
                        />
                    </pui-form-group>
                    <pui-button
                        :small="true"
                        icon="delete"
                        class="fc-form-exclusions__remove-button"
                        @click="emitCorrectionRemoval(correction.internalId)"
                    >
                        {{ $t('setup.scenario.ceForm.tables.flagged.form.corrections.buttons.removeCorrection') }}
                    </pui-button>
                </div>
            </div>
        </template>
        <pui-button
            :small="true"
            icon="add"
            state="primary"
            class="fc-form-corrections__add-button"
            @click="addNewCorrection"
        >
            {{ $t('setup.scenario.ceForm.tables.flagged.form.corrections.buttons.addCorrection') }}
        </pui-button>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ViewResponse } from '@/service-proxies/service-proxies.g';
import { IFormCostModification, OperationType } from '@/models/steps/cost-correction';
import { PuiSelectOption } from '@/models/pebble-ui';
import {
    computeAfterCorrectionTotals,
    computeAfterExclusionTotals
} from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-computations';
import Decimal from 'decimal.js';

@Component({})
export default class FlaggedCostsFormCorrections extends Vue {
    @Prop({ required: true })
    private beforeValues!: Record<number, Decimal>;

    @Prop({ required: true })
    private budgetRequest!: ViewResponse;

    @Prop({ required: true, default: () => [] })
    private costModifications!: IFormCostModification[];

    @Prop({ required: true, default: () => [] })
    private mtpYearOptions!: PuiSelectOption<number>[];

    @Prop({ required: true, default: () => [] })
    private mtcYearOptions!: PuiSelectOption<number>[];

    private hasTriggeredValidation = false;

    private get corrections(): IFormCostModification[] {
        return this.costModifications.filter(e => e.costModificationType === OperationType.CORRECTION);
    }

    private get afterExclusionValues(): Record<number, Decimal> {
        return computeAfterExclusionTotals(this.beforeValues, this.costModifications.filter(e => e.costModificationType === OperationType.EXCLUSION));
    }

    private mounted(): void {
        this.updateCorrectionAmounts();
    }

    public validateCorrection(correction: IFormCostModification): void {
        if (!correction.fromYear) {
            this.emitCorrectionChange({
                ...correction,
                errorMessage: this.$t('setup.scenario.ceForm.tables.flagged.form.corrections.error.fromYearRequired')
            });
            return;
        }

        if (!correction.toYear) {
            this.emitCorrectionChange({
                ...correction,
                errorMessage: this.$t('setup.scenario.ceForm.tables.flagged.form.corrections.error.toYearRequired')
            });
            return;
        }

        if (correction.fromYear === correction.toYear) {
            this.emitCorrectionChange({
                ...correction,
                errorMessage: this.$t('setup.scenario.ceForm.tables.flagged.form.corrections.error.sameYear')
            });
            return;
        }

        if (this.corrections.filter(e => e.fromYear === correction.fromYear && e.toYear === correction.toYear).length > 1) {
            this.emitCorrectionChange({
                ...correction,
                errorMessage: this.$t('setup.scenario.ceForm.tables.flagged.form.corrections.error.multipleCorrections')
            });
            return;
        }

        this.emitCorrectionChange({
            ...correction,
            errorMessage: undefined,
        });
    }

    public async validateForm(): Promise<boolean> {
        return new Promise((resolve) => {
            this.hasTriggeredValidation = true;
            this.corrections.forEach(this.validateCorrection);

            this.$nextTick(() => {
                resolve(this.corrections.filter(e => !!e.errorMessage).length === 0);
            });
        });
    }

    private onFromYearChange(newFromYear: number, correction: IFormCostModification): void {
        this.emitCorrectionChange({
            ...correction,
            fromYear: newFromYear,
        });

        this.$nextTick(() => {
            this.updateCorrectionAmounts();
        })
    }

    private onToYearChange(newToYear: number, correction: IFormCostModification): void {
        this.emitCorrectionChange({
            ...correction,
            toYear: newToYear,
        });

        this.$nextTick(() => {
            this.updateCorrectionAmounts();
        })
    }

    private onPercentageChange(newPercentage: string, correction: IFormCostModification): void {
        const parsedValue = parseFloat(newPercentage);

        this.emitCorrectionChange({
            ...correction,
            percentage: isNaN(parsedValue) ? 0 : parsedValue,
        });

        this.$nextTick(() => {
            this.updateCorrectionAmounts();
        })
    }

    private formatCorrectionAmount(amount: Decimal): string {
        if (!amount || amount.isNaN()) {
            return 'N/A';
        }

        return amount.toString();
    }

    private getNewCostModificationId(): number {
        const maxId = Math.max(...this.costModifications.map(e => e.internalId), 1);
        return maxId + 1;
    }

    private updateCorrectionAmounts(): void {
        computeAfterCorrectionTotals(this.afterExclusionValues, this.corrections, (correction, amount) => {
            this.emitCorrectionChange({
                ...correction,
                amount,
            });
        });

        this.$nextTick(() => {
            if (this.hasTriggeredValidation) {
                this.corrections.forEach(this.validateCorrection);
            }
        });
    }

    private addNewCorrection(): void {
        this.emitCorrectionChange({
            id: -1,
            internalId: this.getNewCostModificationId(),
            fromYear: 0,
            toYear: 0,
            percentage: 0,
            amount: new Decimal(0),
            costModificationType: OperationType.CORRECTION,
            budgetRequestExclusionsId: this.budgetRequest.id,
        });
    }

    private emitCorrectionChange(newFormModification: IFormCostModification): void {
        this.$emit('change:form-modification', newFormModification);
    }

    private emitCorrectionRemoval(internalId: number): void {
        this.$emit('remove:form-modification', internalId);
    }
}
</script>

<style lang="scss">
.fc-form-corrections {
    &__error-notification {
        @include rem(margin-top, pui-spacing(s));
    }

    &__add-button {
        @include rem(margin-top, pui-spacing(s));
    }

    &__remove-button {
        @include rem(margin-top, pui-spacing(xxl));
    }

    .pui-form-grid-row {
        padding: 0 !important;
    }
}
</style>
