<template>
    <div class="fc-form-overview">
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.form.overview.before') }}
        </pui-headline>
        <flagged-costs-form-overview-year-display :values="beforeValues" />
        <div class="fc-form-overview__separator" />
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.form.overview.afterExclusion') }}
        </pui-headline>
        <flagged-costs-form-overview-year-display :values="afterExclusionValues" />
        <div class="fc-form-overview__separator" />
        <pui-headline type="h4">
            {{ $t('setup.scenario.ceForm.tables.flagged.form.overview.afterCorrection') }}
        </pui-headline>
        <flagged-costs-form-overview-year-display :values="afterCorrectionValues" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IFormCostModification, OperationType } from '@/models/steps/cost-correction';
import {
    computeAfterCorrectionTotals,
    computeAfterExclusionTotals
} from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-computations';
import FlaggedCostsFormOverviewYearDisplay
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-overview-year-display.vue';
import Decimal from 'decimal.js';

@Component({
    components: {
        FlaggedCostsFormOverviewYearDisplay,
    }
})
export default class FlaggedCostsFormOverview extends Vue {
    @Prop({ required: true })
    private beforeValues!: Record<number, Decimal>;

    @Prop({ required: true, default: () => [] })
    private costModifications!: IFormCostModification[];
    
    private get afterExclusionValues(): Record<number, Decimal> {
        return computeAfterExclusionTotals(this.beforeValues, this.costModifications.filter(e => e.costModificationType === OperationType.EXCLUSION));
    }

    private get afterCorrectionValues(): Record<number, Decimal> {
        return computeAfterCorrectionTotals(this.afterExclusionValues, this.costModifications.filter(e => e.costModificationType === OperationType.CORRECTION));
    }
}
</script>

<style scoped lang="scss">
.fc-form-overview {
    @include pui-box();
    @include rem(padding, pui-spacing(s));
    
    width: fit-content;

    &__row {
        @include rem(margin-top, pui-spacing(s));
        @include rem(gap, pui-spacing(s));

        display: flex;

        &__value {
            &__year {
                color: $grey-light;
            }

            &--empty {
                color: $grey-light;
            }

            &--positive {
                color: $black;
            }

            &--negative {
                color: $red-darker;
            }
        }

        &__spacer {
            flex-grow: 1;
            max-width: 7.5rem;
        }
    }

    &__separator {
        @include rem(margin-top, pui-spacing(s));
        @include rem(margin-bottom, pui-spacing(s));

        height: 1px;
        background-color: $grey-light;
    }
}
</style>
