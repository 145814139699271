<template>
    <pui-loader :promise="initialPromise">
        <div class="fc-form">
            <flagged-costs-form-overview
                :before-values="beforeValues"
                :cost-modifications="costModifications"
            />
            <pui-form-group
                :label="$t('setup.scenario.ceForm.tables.flagged.form.justification')"
                :has-label-padding="false"
                v-pui-form-grid-column="11"
            >
                <pui-form-textarea
                    v-model="justification"
                />
            </pui-form-group>
            <flagged-costs-form-exclusions
                :ref="REF_CONSTANTS.EXCLUSIONS_SECTION"
                :budget-request="budgetRequest"
                :cost-modifications="costModifications"
                :mtp-year-options="mtpYearOptions"
                @change:form-modification="handleFormModificationChange"
                @remove:form-modification="handleFormModificationRemove"
            />
            <flagged-costs-form-corrections
                :ref="REF_CONSTANTS.CORRECTIONS_SECTION"
                :before-values="beforeValues"
                :budget-request="budgetRequest"
                :cost-modifications="costModifications"
                :mtp-year-options="mtpYearOptions"
                :mtc-year-options="mtcYearOptions"
                @change:form-modification="handleFormModificationChange"
                @remove:form-modification="handleFormModificationRemove"
            />
        </div>
    </pui-loader>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    BudgetRequestServiceProxy,
    CostModificationDto,
    EditBudgetsRequest,
    IGetScenarioResponse,
    ViewResponse
} from '@/service-proxies/service-proxies.g';
import FlaggedCostsFormOverview
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-overview.vue';
import FlaggedCostsFormExclusions
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-exclusions.vue';
import FlaggedCostsFormCorrections
    from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-corrections.vue';
import { IFormCostModification } from '@/models/steps/cost-correction';
import Decimal from 'decimal.js';
import { PuiSelectOption } from '@/models/pebble-ui';
import { mapToSelectOption } from '@/components/cost-exclusion-step/flagged-costs-lightbox/flagged-costs-form/flagged-costs-form-utils';

const REF_CONSTANTS = {
    EXCLUSIONS_SECTION: 'exclusions',
    CORRECTIONS_SECTION: 'corrections',
} as const;

@Component({
    components: {
        FlaggedCostsFormOverview,
        FlaggedCostsFormExclusions,
        FlaggedCostsFormCorrections,
    }
})
export default class FlaggedCostsForm extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly budgetRequestService = new BudgetRequestServiceProxy();

    @Prop({ required: true })
    private budgetRequestId!: number;

    private initialPromise: Promise<any> | null = null;

    private budgetRequest: ViewResponse | null = null;
    private costModifications: IFormCostModification[] = [];

    private justification = '';

    $refs!: {
        [REF_CONSTANTS.EXCLUSIONS_SECTION]: FlaggedCostsFormExclusions,
        [REF_CONSTANTS.CORRECTIONS_SECTION]: FlaggedCostsFormCorrections,
    };

    private get scenarioId(): number {
        return this.$store.getters['scenario/getScenarioId'];
    }
    
    private get scenario(): IGetScenarioResponse {
        return this.$store.getters['scenario/getScenario'];
    }

    private get mtpYear(): number {
        return this.scenario.mtpYear ?? 0;
    }
    
    private get yearBudgetKeyMap(): Record<number, 'forecast' | 'new1' | 'new2' | 'new3'> {
        return {
            [this.mtpYear]: 'forecast',
            [this.mtpYear + 1]: 'new1',
            [this.mtpYear + 2]: 'new2',
            [this.mtpYear + 3]: 'new3'
        };
    }

    private get startYear(): number {
        if (!this.scenario.startYear) {
            return 0;
        }

        return this.mtpYear < this.scenario.startYear ? this.mtpYear : this.scenario.startYear;
    }

    private get endYear(): number {
        if (!this.scenario.endYear) {
            return 0;
        }

        const endMtpYear = this.mtpYear + 3;

        return this.scenario.endYear < endMtpYear ? endMtpYear : this.scenario.endYear;
    }

    private get years(): number[] {
        const years: number[] = [];

        for (let i = this.startYear; i <= this.endYear; i++) {
            years.push(i);
        }

        return years;
    }

    private get mtcYearOptions(): PuiSelectOption<number>[] {
        return mapToSelectOption(this.years)
    }

    private get mtpYears(): number[] {
        return [this.mtpYear, this.mtpYear + 1, this.mtpYear + 2, this.mtpYear + 3];
    }

    private get financialYears(): number[] {
        if (!this.budgetRequest?.yearsFinancialValues) {
            return []
        }

        return Object.keys(this.budgetRequest.yearsFinancialValues).map(e => parseInt(e))
    }

    private get mtpYearOptions(): PuiSelectOption<number>[] {
        const financialYears = mapToSelectOption(this.financialYears)
        const mtpYears = mapToSelectOption(this.mtpYears)
        
        return [...financialYears, ...mtpYears]
    }

    private get financialValuesList(): { key: number; value: number }[] {
        if (this.budgetRequest?.yearsFinancialValues) {
            return Object.entries(this.budgetRequest.yearsFinancialValues).map(([key, value]) => ({
                key: parseInt(key),
                value
            }))
        }

        return []
    }

    private get beforeValues(): Record<number, Decimal> {
        const values: Record<number, Decimal> = {};

        if(!this.budgetRequest) {
            return values
        }

        this.years.forEach(year => {
            const budgetKey = this.yearBudgetKeyMap[year]
            values[year] = new Decimal(budgetKey ? this.budgetRequest?.[budgetKey] ?? 0 : 0);
        });
        
        this.financialValuesList.forEach(entry => {
            values[entry.key] = new Decimal(entry.value)
        })

        return values;
    }

    private mounted(): void {
        this.initialPromise = Promise.all([
            this.budgetRequestService.viewBudget(this.budgetRequestId)
                .then(response => {
                    this.budgetRequest = response.result;
                    this.justification = response.result.justification ?? '';
                    this.emitNewGlobalId(this.budgetRequest.globalId ?? '');

                    const costModificationDtos = this.budgetRequest.costModifications ?? [];
                    this.costModifications = [];
                    for (let i = 0; i < costModificationDtos.length; i++) {
                        this.costModifications.push({
                            ...costModificationDtos[i],
                            internalId: i,
                            amount: new Decimal(costModificationDtos[i].amount),
                        });
                    }
                })
        ]);
    }

    private emitNewGlobalId(globalId: string): void {
        this.$emit('change:global-id', globalId)
    }

    private handleFormModificationChange(changedFormModification: IFormCostModification): void {
        if (this.costModifications.find(e => e.internalId === changedFormModification.internalId)) {
            this.costModifications = [...this.costModifications.filter(e => e.internalId !== changedFormModification.internalId), changedFormModification];
        } else {
            this.costModifications = [...this.costModifications, changedFormModification];
        }

        this.costModifications.sort((a, b) => a.internalId - b.internalId);
    }

    private handleFormModificationRemove(removedInternalId: number): void {
        this.costModifications = this.costModifications.filter(e => e.internalId !== removedInternalId);
    }

    public async submitForm(): Promise<any> {
        if (!(await this.$refs.exclusions.validateForm()) || !(await this.$refs.corrections.validateForm())) {
            return false;
        }

        const costModifications = this.costModifications.map(e => new CostModificationDto({
            ...e,
            amount: e.amount.toNumber(),
        }));

        this.initialPromise = this.budgetRequestService.editBudget(new EditBudgetsRequest({
            scenarioId: this.scenarioId,
            budgetRequestExclusionId: this.budgetRequestId,
            justification: this.justification,
            costModifications,
        })).then(() => {
            this.$emit('close:form', true);
            return Promise.resolve(true);
        }).catch(() => {
            this.$pui.toast({
                type: 'error',
                title: this.$t('form.toastMessages.formSubmitError.title'),
                copy: this.$t('form.toastMessages.formSubmitError.copy'),
            });
            return Promise.resolve(false);
        });

        return this.initialPromise;
    }
}
</script>

<style scoped lang="scss">
.fc-form {
    @include rem(gap, pui-spacing(l));

    display: flex;
    flex-direction: column;

    padding-bottom: 15rem;
}
</style>
